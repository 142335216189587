import {useState} from 'react';
import {ethers, BigNumber} from 'ethers';
import cur from './CUR.json'
import { Box, Button, Flex, Input, Text, Image } from '@chakra-ui/react';
import Hidden from "./assets/hidden.gif";
import { wait } from '@testing-library/user-event/dist/utils';

const cur_address = "0x0000000000000000000";
const MainMint =({accounts, setAccounts}) => {
    const [mintAmount, setMintAmount] = useState(1);
    const isConnected = Boolean (accounts[0]);
    
    
    return(
    <Flex justify="center" align = "center" height="100vh" paddingBottom="150px">   
    
        <Box width = "666px"   backgroundColor="#EDFF04DD" paddingBottom="69px" paddingTop="49px" marginTop="120">
            <div>
                <Image src ={Hidden} boxSize = "256px" margin=" 0 15px"/> 
                <Text fontSize ="24px" maxWidth ="33%" marginLeft="33%" backgroundColor="#F69" color="yellow" fontWeight="666">TH3 CURS3D</Text>
                <Text fontSize ="16px" maxWidth ="75%" marginLeft="12.5%" backgroundColor="#F69" color="yellow" fontWeight="666">...minting soon...<br></br>genesis (x500)->terra(x1500)->divum(x1500)->astra(x500)</Text>
                <Text backgroundColor="#222" maxWidth="80%" align="center" color="yellow" marginLeft="10%">malformed, twisted, all too human! th3 curs3d are a series of generative portraits with a <i>twist</i>. each character is generated procedurally in 3D, deformed with a splash of our special ~curse~ sauce and rendered in pixel art style.</Text>
                <Text backgroundColor="#222" maxWidth="80%" align="center" color="yellow" marginLeft="10%">could these curs3s be removed? could someone break the spell and unburden th3 curs3d? stick around and enjoy the journey!</Text>
            </div>
            
        </Box>

       
    </Flex>

    );
};

export default MainMint;