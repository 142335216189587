import React from 'react';
import Modal from 'react-modal';
import {useState} from 'react';
import TeamModal from "./TeamModal";
import { Box, Button, Flex, Grid, Image, Link, Spacer, Text } from '@chakra-ui/react';
import { Container, Row, Col } from 'react-grid-system';
import Discord from "./assets/socials/dc_logo.png";
import Twitter from "./assets/socials/tw_logo.png";
import Instagram from "./assets/socials/inst_logo.png";
import sarah from "./assets/team/sarah.png";
import pascal from "./assets/team/pascal.png";
import emily from "./assets/team/emily.png";
import threedtt from "./assets/about/3dtt.gif";
import twodtt from "./assets/about/2dtt.gif";
import twod from "./assets/about/2dtt.png";


const NavBar = ({accounts, setAccounts}) =>{
    const isConnected = Boolean(accounts[0]);

    const [show, setShow] = useState(false);
    const [showA, setShowA] = useState(false);
    const [showC, setShowC] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => {setShow(true); console.log("showing modal:", show );}

    const handleCloseA = () => setShowA(false);
    const handleShowA = () => {setShowA(true); console.log("showing modal:", show );}

    const handleCloseConnect = () => setShowC(false);
    const handleShowConnect = () => {setShowC(true); console.log("showing modal:", show );}

    async function connectAccount(){
        if(window.ethereum){
            const accounts = await window.ethereum.request({method: "eth_requestAccounts",});
            setAccounts(accounts);
        }
    }

    function TeamPage(){
        console.log("team");
    };
    
    return( 
        <Flex justify ="space-between" align = "center" padding="30px" backgroundColor="#EDFF04DD" height="100px">
            
            {/*social icons on left*/}
            <Flex justify ="space-around" width = "10%" padding=" 0 75x"> 
                <Link href = "https://discord.gg/AJsKSgw4dY">
                    <Image src = {Discord} boxSize = "48px" margin=" 0 15px" />
                </Link>
                <Link href = "https://twitter.com/th3_curs3d">
                    <Image src = {Twitter} boxSize = "48px" margin=" 0 15px" />
                </Link>
            
            </Flex>

            <Flex><Text fontSize = "36px"
                    fontWeight="200" >_-_-_TH3-CURS3D NFTs_-_-_</Text></Flex>

            <Flex justify ="space-around" width = "15%" padding=" 0 75x"> 
                {/*buttons on right*/}
                <Button color="yellow" backgroundColor="#F69" paddingTop="10px" 
                    fontSize = "18px"
                    fontWeight="600" 
                    fontFamily="inherit"
                    padding="10px"
                    margin="0 15px"
                    border = "0"
                    onClick={handleShowA}
                    >&nbsp;about&nbsp;
                </Button>
                <Button color="yellow" backgroundColor="#F69" paddingTop="10px" 
                    fontSize = "18px"
                    fontWeight="600" 
                    fontFamily="inherit"
                    padding="10px"
                    margin="0 15px"
                    border = "0"
                    onClick={handleShow}
                    >&nbsp;team&nbsp;
                </Button>
                
                <Spacer />

                <Spacer />
                 {/*connect*/}
            {showC? (<Box margin="0 45px" width="140px"><Text backgroundColor="#222" color="#1FFF00">&nbsp;NOT YET!&nbsp;</Text></Box>) : (
                <Button 
                    backgroundColor="#F69F"
                    fontFamily="inherit"
                    color="yellow"
                    padding="10px"
                    margin="0 15px"
                    onClick={handleShowConnect}>CONNECT
                </Button>)}
                {<div>
                    <Modal className="modal" isOpen={show} contentLabel="TEAM" align= "center">
                        <Button border="0" backgroundColor="yellow" color="#F69F69" fontWeight="600" marginLeft="90%" onClick={handleClose}>[ X ]</Button>
                        <Text fontWeight="600">M33T TH3 T3AM</Text>
                        <Text fontWeight="400" width="66%" marginLeft="16%" textAlign="justify">We are a small team of professional artist-developer hybrids. The proliferation of ugly art on the web has inspired us to create series of beautifully flawed portraits.  
                            Join as in our adventure to reconsider what it may mean to be human in terms of aesthetics, focusing on potential flaws and perfectly exaggerating them to salient imperfections - curs3ing them with humanhood!</Text>
                        
                        <Container>
                            <Row>
                                <Col sm={4} align="center">
                                     <Image src={sarah} width="128px"></Image>   
                                     <Text backgroundColor="yellow" width ="80px" color="#F69F69" fontWeight="600">5arah</Text>  
                                     <Text backgroundColor="#222" width ="80%" color="#F69F69" >Passionate about character design and storytelling, 5arah is one of the artists behind the legion. She is the creative current of the legion. </Text>                                                    
                                </Col>
                                <Col sm={4}align="center">
                                    <Image src={pascal} width="128px"></Image>                                                       
                                    <Text backgroundColor="yellow" width ="80px" color="#F69F69" fontWeight="600">Pasca1</Text>                                                    
                                    <Text backgroundColor="#222" width ="80%" color="#F69F69" >The original curs3d. Pasca1 has been blessed with multiple curses, he contributes in both art and programming. His zeal has made it all possible</Text>                                                    
                                </Col>
                                <Col sm={4}align="center">
                                    <Image src={emily} width="128px"></Image>                                                       
                                    <Text backgroundColor="yellow" width ="80px" color="#F69F69" fontWeight="600">3mily</Text>                                                    
                                    <Text backgroundColor="#222" width ="80%" color="#F69F69" >Our in-house witch. Cracking away at arcane runes and glyphs day in, day out, 3mily is the psyhic powerhouse behind the legion.</Text>       
                                </Col>
                            </Row>
                        </Container>
                    
                    </Modal>
                </div>}
                
                {<div>
                    <Modal className="modal" isOpen={showA} contentLabel="ABOUT" align= "center">
                        <Button border="0" backgroundColor="yellow" color="#F69F69" fontWeight="600" marginLeft="90%" onClick={handleCloseA}>[ X ]</Button>
                        <Text fontWeight="600">ABOUT</Text>
                        <Text fontWeight="400" width="66%" marginLeft="16%" textAlign="justify">We have developed a programmable human head model in 3D which we use as a basis. Employing our super-secret character generation algorithm, we are able to generate virtually infinite number of characters. The resulting portraits are then cursed in 3D space through a cursing-algorithm and rendered to 2D portraits.
                        After all the seals have been broken, the owners of the tokens would be able to interact with the characters through a chat interface, potentially untwisting their fate.</Text>
                        
                        <Container>
                            <Row>
                                <Col sm={4} align="center">
                                     <Image mixBlendMode="exclusion" src={threedtt} width="256px"></Image>   
                                     <Text backgroundColor="#222" width ="80%" color="#F69F69" >3D GENERATION</Text>                                                    
                                </Col>
                                <Col sm={4}align="center">
                                    <Image mixBlendMode="exclusion" src={twodtt} width="256px"></Image>                                                       
                                    <Text backgroundColor="#222" width ="80%" color="#F69F69" >RENDERING</Text>                                                    
                                </Col>
                                <Col sm={4}align="center">
                                    <Image mixBlendMode="exclusnormalion" src={twod} width="256px"></Image>                                                       
                                    <Text backgroundColor="#222" width ="80%" color="#F69F69" >FINAL(?) FORM</Text>                                                    
                                </Col>
                            </Row>
                        </Container>
                    
                    </Modal>
                </div>}

            </Flex>
            
        </Flex>
  

    );
};


export default NavBar;