import {useState} from 'react';
import './App.css';
import MainMint from './MainMint';
import NavBar from './NavBar';
import TeamModal from './TeamModal';

function App() {

  const [accounts, setAccounts] = useState([]);
  return (
    <div>
      <div className="App">
        <NavBar accounts ={accounts} setAccounts = {setAccounts} />
        <MainMint accounts ={accounts} setAccounts = {setAccounts} />
      </div>
      <div className="bg">

      </div>
    </div>
  );
}

export default App;
